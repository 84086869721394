//@ts-ignore
import React, {FC, useMemo, useState} from 'react';
import {Articles} from "../utils/common-types";
import ArticlePreview from '../components/article-preview'
import {ButtonOnClick, VariantRed} from "../components/buttons";

const ArticleGrid: FC<{articles: Articles} & { cta?: boolean, showAll?: boolean }> = ({articles, cta, showAll}) => {
  const numCols = 3; // prob want to make this configurable?
  const initialNumRows = 3; // this too?
  const [visibleRowCount, setVisibleRowCount] = useState(initialNumRows);
  let visibleArticles: Articles = [];
  let more = false;
  if (articles && articles.length) {
    visibleArticles = showAll ? articles : articles.slice(0, numCols * visibleRowCount)
    more = showAll ? false : visibleArticles && visibleArticles.length < articles.length
  }


  return (
    <>
      {/*fixme: can't use numCols to create md:grid-cols-${numCols},
          because its filtered out by the remove unused tailwind classes plugin*/}
      <div className={`pt-4 grid grid-cols-1 md:grid-cols-3 gap-4`}>
        {visibleArticles.map((article, index) => (
          <div key={index} style={visibleArticles.length == 1 ? {gridColumnStart:'2'} : {}}>
            <ArticlePreview article={article} displayDescription={true} cta={cta}/>
          </div>
        ))}
      </div>
      {more &&
        <div className="w-full text-center py-6">
          <ButtonOnClick
            className="bg-red text-black"
            variant={VariantRed}
            onClick={() => setVisibleRowCount(visibleRowCount + 1)}>View more</ButtonOnClick>
        </div>
      }
    </>
  )
}

export default ArticleGrid;
