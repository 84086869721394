import React from 'react'
import {graphql} from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import {LGHeader, MarkdownContainer, MDHeader} from '../components/typography';
import {Helmet} from "react-helmet";
import ArticleGrid from "../components/article-grid";
import Img from "gatsby-image";
import {ButtonNewWindow, VariantRed} from "../components/buttons";

const SolutionPageTemplate = ({data, location}) => {
  const solution = get(data, 'contentfulSolutionPage')
  const siteTitle = get(data, 'site.siteMetadata.title')

  return (
    <Layout location={location}>
      <Helmet title={`${solution.title} | ${siteTitle}`} />
      <section className="p-8 md:p-16 text-white">
        <div className="max-w-screen-lg mx-auto text-center md:px-16">
          <Img
            alt={solution.title}
            fluid={solution.heroImage.fluid}
          />
          <LGHeader className="py-6">{solution.title}</LGHeader>
          <MarkdownContainer className="py-8 text-justify" useSourceSans>
            {solution.body.childMarkdownRemark.html}
          </MarkdownContainer>
        </div>
        {solution.callToActionButtonText && solution.callToActionButtonUrl && <div className="mb-8 text-center">
          <ButtonNewWindow to={solution.callToActionButtonUrl} variant={VariantRed}>
            {solution.callToActionButtonText}</ButtonNewWindow>
        </div>}
        {(solution.callToActionRow || solution.articles) && <MDHeader className="py-6">Related Links</MDHeader>}
        {solution.callToActionRow && <ArticleGrid articles={solution.callToActionRow} cta/>}
        {solution.articles && <ArticleGrid articles={solution.articles} />}
      </section>
    </Layout>
  )
}

export default SolutionPageTemplate

export const pageQuery = graphql`
  query SolutionPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulSolutionPage(slug: { eq: $slug }) {
      heroImage {
        fluid(maxWidth: 900, maxHeight: 600, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      title
      body {
        childMarkdownRemark {
          html
        }
      }
      callToActionButtonText
      callToActionButtonUrl
      callToActionRow {
        title
        url
        heroImage {
          fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      articles {
        ... on ContentfulBlogPost {
          title
          slug
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulArticle {
          title
          url
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
