import React from 'react'
import get from 'lodash/get'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import {SmHeader} from "./typography"

import './article-preview.css';
import {Button, VariantYellow} from "./buttons";

export const ArticlePreviewHorizontal = ({article}) => {
  const linkContent = (<div className="text-white text-center hover-yellow-text grid grid-cols-1 md:grid-cols-3 gap-4">
    {article.heroImage && <Img alt="" fluid={article.heroImage.fluid} className="col-span-1"/>}
    <div className="col-span-2 text-left">
      <SmHeader className="pt-4 px-2 min-h-16 flex">{article.title}</SmHeader>
      {article.description && <div
        className="px-2"
        style={{flexGrow: 1}}
        dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }}
      />}
    </div>
  </div>)

  if (article.url) {
    return (
      <a href={article.url} target="_blank" rel="noreferrer" className="article-preview-horizontal">
        {linkContent}
      </a>
    )
  }
  return (
    <Link to={`/blog/${article.slug}`} className="article-preview-horizontal">
      {linkContent}
    </Link>
  )
}

const ArticlePreview = ({ article, displayDescription, cta }) => {
  // console.log("description", article.description.childMarkdownRemark.html)

  const linkContent = (
    <div className="text-white text-center hover-yellow-text flex flex-col h-full">
      {article.heroImage && <Img alt="" fluid={article.heroImage.fluid} />}
      <SmHeader className="pt-4 px-4 min-h-16 md:min-h-24 flex justify-center items-center">{article.title}</SmHeader>
      {(displayDescription && article.description) && <div
        className="px-2"
        style={{flexGrow: 1}}
        dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }}
      />}
      {cta && <Button className="my-4 mx-4 max-w-40 self-center" variant={VariantYellow}>Take Action</Button>}
    </div>
  )

  if (article.url) {
    return <a href={article.url} target="_blank" rel="noreferrer" className="article-preview">
      {linkContent}
    </a>
  }
  // todo: make every query catch this so we don't have to check for URL anymore.
  const contentType = get(article, 'sys.contentType.sys.id', 'blogPost')
  const contentTypeUrlPrefixMap = {'blogPost': '/blog/', 'solutionPage': '/solutions/', 'customLinkTree': '/links/'}
  const linkTo = article.overrideUrl || `${contentTypeUrlPrefixMap[contentType]}${article.slug}`
  return (
    <Link to={linkTo} className="article-preview">
      {linkContent}
    </Link>
  )
}

export default ArticlePreview;
